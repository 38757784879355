import { 
    Group,
    Mesh,
    MeshBasicMaterial 
} from 'three';

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import params from './parameters';
import { animateOnScroll } from './StartSceneScrollAnimation';
import MyImages from './Images';

const images = new MyImages();
let zPosition = params.years.meshOptions.zAxis.position;

export default class Years{
    constructor(){
        this.sceneGroup= new Group(),
        this.currentYear = new Date().getFullYear(),
        this.startYear = params.years.startYear,
        this.yearnumbers = [],
        this.decadenumbers = [], 
        this.yearTitle = [],
        this.decadeBGImages=[],
        this.nrOfYears = this.currentYear - this.startYear,
        this.decadesLength = Math.ceil(this.nrOfYears/10),
        this.bgImgCounter = 0,
        this.fontSizes = params.years.fontSizes

        this.sceneGroup.position.z = this.nrOfYears * params.years.meshOptions.zAxis.position * (-1) + params.years.meshOptions.zAxis.position

        for(let i = this.startYear; i < this.currentYear; i++){
            this.yearnumbers.push(i);
            if (i % 10 == 0) {
                this.decadenumbers.push(i)
            }
        }
        for(let i = 0; i < this.yearnumbers.length; i++){
            this.yearTitle.push('Beispieltitel' + this.yearnumbers[i].toString());
        }
        for (let i=0; i <= this.decadesLength; i++){
            this.decadeBGImages.push(images.url + images.filenames[ i % images.filenames.length]);
        }

    }

    createTextGeometry(text, i, font, fontsize){
        var textGeometry = new TextGeometry(
            text[i].toString(),
            {   
                font:font,
                size: (this.yearnumbers[i] % 10 != 0) ? fontsize.nondecade : fontsize.decade , 
                height: 0
            }
        );
        textGeometry.center();
        textGeometry.computeBoundingBox(); 
        return textGeometry;
    }

    editMesh(mesh, xpos, ypos, zpos, id, year){
        mesh.position.z = zpos;
        mesh.detailContentIdx = id;       
        if(year % 10 != 0){
            mesh.position.y = (Math.random() - 0.5)*ypos;
            mesh.position.x = (year % 2 == 0) ? Math.random()*xpos + 1 : -(Math.random()*xpos + 1);
        }
    }
    editMeshPortrait(mesh, xpos, ypos, zpos, id, year){
        mesh.position.z = zpos;
        mesh.detailContentIdx = id;       
        if(year % 10 != 0){
            let angle = Math.random()*2*Math.PI;
            mesh.position.y = Math.cos(angle) * (3 + Math.random()*ypos);
            mesh.position.x = Math.sin(angle) * (3+ Math.random()*xpos);
        }
    }
    createTextGeometryMeshes(scopes, font, mediaOrientation){
        
        for(let i = 0; i < this.yearnumbers.length; i++){
            var textGeometryThisYear = this.createTextGeometry(this.yearnumbers, i, font, this.fontSizes.forYears); 
            var textMaterial = new MeshBasicMaterial({
                color: params.colors.textcolor,
                transparent: true,
            });
            scopes.MaterialsOfYears.push(textMaterial);
            var yearMesh = new Mesh(textGeometryThisYear, textMaterial);
            if(mediaOrientation == 'landscape'){
                this.editMesh(yearMesh, 4, 5, zPosition, i, this.yearnumbers[i]);
            }else{
                this.editMeshPortrait(yearMesh, 2, 3, zPosition, i, this.yearnumbers[i]);
            }            
            zPosition += params.years.meshOptions.zAxis.position;
            scopes.MeshesOfYears.push(yearMesh);
            this.sceneGroup.add(yearMesh);

            var textGeometryTitle = this.createTextGeometry(this.yearTitle, i, font, this.fontSizes.forTitle);
            var titleMesh = new Mesh(textGeometryTitle, scopes.MaterialsOfYears[i]);
            titleMesh.position.x = yearMesh.position.x;
            titleMesh.position.y = (this.yearnumbers[i] % 10 != 0) ? yearMesh.position.y - 0.4 : yearMesh.position.y - 1.5; 
            titleMesh.position.z = yearMesh.position.z;
            this.sceneGroup.add(titleMesh);
        }
    }

    animate(sizes){
        return animateOnScroll(this, sizes);
    }


}