import $ from "jquery";
import { writeTimelineHTML } from "./JS/TimelineSidebar";

writeTimelineHTML();

$('#startTimeTravel').on('click', () =>{
    startTimeTravel();
});
    

const startTimeTravel = () =>{
    $('html').removeClass('o-hidden');
    $('body').removeClass('o-hidden');
    $('#landingpage').addClass('none');
    $('#hamburger').removeClass('none');
    $('#logo').removeClass('none');
    $('#timelinewrap .marker').css('top', 'calc(' + document.querySelector('#timeline li:last-child').getBoundingClientRect().top.toString() + 'px + 0.5em)')
}


// STYLINGS
const timelineWrapper = document.getElementById('timelinewrap');
const vorspannbox = document.getElementById('vorspannbox');
vorspannbox.style.width = 'calc(100vw - ' + (timelineWrapper.clientWidth + 200) + 'px)';

$('#timelinewrap .marker').css('top', 'calc(' + document.querySelector('#timeline li:first-child').getBoundingClientRect().top.toString() + 'px + 0.5em)')
