const urls = {
    echonet: '/assets/content/',
    vsCode: 'sh-assets/media/'
}

export default class MyImages{
    constructor(){
        this.url = urls.vsCode;
        this.stage = urls.vsCode +'Eroeffnung_BG_02.jpg',
        this.filenames = [
            'Eisrevue_BG.jpg',
            'Eroeffnung_BG_02.jpg',
            'sample1.jpg',
            'sample2.jpg',
            'Grundsteinlegung_BG.jpg',
            'sh1.jpg',
            'sh2.jpg',
            'sh3.jpg',
            'sh4.jpg',
            'sh5.jpg',
            'sh6.jpg',
        ];
    }
}
