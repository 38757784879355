import Years from "./Years"
import * as THREE from 'three'
import params from "./parameters";
import Testimages from "./Testimages";
import HTML from "./HTML";
import gsap from 'gsap'

// Create a texture loader so we can load our image file
const textureLoader = new THREE.TextureLoader()

export default class Detailcontent{
    constructor(idx){
        this.idx = idx,
        this.years = new Years();
        this.year = this.years.yearnumbers[idx],
        this.title = this.years.yearTitle[idx],
        this.subtitle= `21.06.${this.years.yearnumbers[idx]}`,
        this.description= 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, nesciunt enim, eligendi cumque dolore debitis illo omnis, molestiae eaque unde rem. Deleniti corporis laudantium, cum blanditiis magnam hic consequatur ea.',
        this.images= [],
        this.textures= [textureLoader.load(Testimages.sample1), textureLoader.load(Testimages.sample2), textureLoader.load(Testimages.landingpage)],
        this.geometrys={
            plane: new THREE.PlaneGeometry(params.detailContent.ImgWidth, params.detailContent.ImgHeight),
            circle: new THREE.CircleGeometry(params.detailContent.stageFloor.radius, 32)
        }, 
        this.materials= [],
        this.meshes= [],
        this.mediaCollage= new THREE.Group(),
        this.mediaCollageRadius= 1,
        this.stageFloor = new THREE.Mesh(this.geometrys.circle, new THREE.MeshBasicMaterial())

    }

    createMeshes(){
        this.stageFloor.rotation.x = -Math.PI * 0.5;
        this.stageFloor.position.y = params.detailContent.stageFloor.yPos;
        this.stageFloor.material.transparent = true;
        this.stageFloor.material.opacity = params.detailContent.stageFloor.opacity;
        for (let i=0; i< this.textures.length; i++) {
            var material = new THREE.MeshBasicMaterial({
                map: this.textures[i],
                side: THREE.DoubleSide
              })
            var mesh = new THREE.Mesh(this.geometrys.plane, material)    
            mesh.position.x = (i % 2 == 0) ? Math.cos(i)*this.mediaCollageRadius : -Math.cos(i-1)*this.mediaCollageRadius ; 
            mesh.position.z = (i % 2 == 0) ? Math.sin(i)*this.mediaCollageRadius  : Math.sin(i-1)*this.mediaCollageRadius ; 
            mesh.position.y = 0;
            // mesh.lookAt(new THREE.Vector3(0,0,0));
            this.meshes.push(mesh);
            this.mediaCollage.add(mesh);
        }
    }

    writeHTML(){
        HTML.detailTitle.innerText = this.title;
        HTML.detailSubtitle.innerText = this.subtitle;
        HTML.detailDescription.innerText = this.description;
    }

    styleHTML(){
        HTML.main.style.backgroundImage = 'url(' + Testimages.stage + ')';
        HTML.detailText.classList.add('visible');
        HTML.goToStartSceneBtn.classList.add('visible');
        HTML.fakeSpotlight.classList.add('active');
        HTML.spotlightOverlay.classList.add('visible');
        HTML.goToHomepage.classList.remove('visible');
        HTML.timelinewrap.classList.remove('visible');
        HTML.controlsInfo.classList.add('visible');
    }

    rotateMeshes(duration){
        for (const mesh of this.meshes) {
            var startRotation = new THREE.Euler().copy(mesh.rotation);
            mesh.lookAt(new THREE.Vector3(0,0,0)); //center of circle
            var endRotation = new THREE.Euler().copy(mesh.rotation);
            mesh.rotation.copy(startRotation);
            gsap.to(mesh.rotation, {duration: duration, y: endRotation.y})
            gsap.to(mesh.rotation, {duration: duration, x: endRotation.x})

        }
        
    }

}