export default {
    html: document.querySelector('html'),
    body: document.body,
    main: document.querySelector('main'),
    timelinewrap: document.getElementById('timelinewrap'),
    timelineMarker: document.querySelector('#timelinewrap .marker'),
    timelineLastChild: document.querySelector('#timeline li:last-child'),
    timelineAnchor: document.getElementsByClassName('timelineelement'),
    goToStartSceneBtn: document.getElementById('goToStartScene'),
    detailText: document.getElementById('detailText'),
    detailTitle: document.getElementById('detailTitle'),
    detailSubtitle: document.getElementById('detailSubtitle'),
    detailDescription: document.getElementById('detailDescription'),
    info: document.getElementById('info'),
    infoTxt: document.getElementById('info-txt'),
    fakeSpotlight: document.getElementById('fake-spotlight'),
    spotlightOverlay: document.getElementById('spotlight-overlay'),
    scrollMarker: document.getElementById('scroll-marker'),
    startTimeTravel: document.getElementById('startTimeTravel'),
    landingpage: document.getElementById('landingpage'),
    goToHomepage: document.getElementById('goToHomepage'),
    controlsInfo: document.getElementById('controls-info'),
}
