import $ from 'jquery'
import * as THREE from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import Debug from './JS/Debug'
import params from './JS/parameters'
import HTML from './JS/HTML'
import Years from './JS/Years'
import Detailcontent from './JS/Detailcontent'
import gsap from 'gsap'
import SetUp from './JS/Setup'


/** ************************************************** ******* **********************************
 * *************************************************** Set-Up *************************************
 * **************************************************** *********** ****************************/

const canvas = document.querySelector('canvas.sh-canvas')
const setUp = new SetUp(canvas);

/**
 * Renderer
 */
const renderer = setUp.renderer;

/**
 * Scenes
 */
const scenes = setUp.scenes;
var currentScene = scenes.startscene;

/**
 * Camera & Controls
 */
const camera = setUp.camera;
currentScene.add(camera);
const frustum = setUp.frustum

const controls = setUp.controls;
controls.enabled = false;
var cameraLooksAt = new THREE.Vector3();
controls.addEventListener('change', ()=>{
    if (camera.getWorldDirection(cameraLooksAt).z >= params.detailContent.zAxisAreaForTextVisibility) {
        HTML.detailText.classList.add('hide');
    }else{
        HTML.detailText.classList.remove('hide');
    }
    HTML.controlsInfo.classList.remove('visible');
});

/**
 * Sizes
 */
window.addEventListener('resize', () =>{
    setUp.updateOnResize()
});

/**
 * Mouse
 */
const mouse = setUp.mouse;
window.addEventListener('mousemove', (e) =>{
    setUp.updateMouseOnMove(e);
});

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()  
let currentIntersect = null



/****************************************** ******** *****************************************
 * ***************************************** STADTHALLE *****************************************
 * ***************************************** ********* *****************************************/
HTML.info.addEventListener('click', () =>{
    if (HTML.infoTxt.classList.length > 0) {
        HTML.infoTxt.classList.remove('none');
    } else {
        HTML.infoTxt.classList.add('none')
    }
});

/**
 * Scopes
 */
let scopes = {
    MaterialsOfYears: [],
    MeshesOfYears: []
}

/**
 * Fonts & Years - Scrollanimation
 */
const myYears = new Years();
scenes.startscene.add(myYears.sceneGroup);


const fontLoader = new FontLoader();

fontLoader.load(
    // '/fonts/helvetiker_regular.typeface.json',
    'sh-assets/fonts/TT_Chocolates_DemiBold_Regular.json',

    (font) =>
    {
        myYears.createTextGeometryMeshes(scopes, font, 'portrait');
        HTML.body.style.height = (setUp.sizes.height + params.bodyScrollMeasure * myYears.yearnumbers.length).toString() + 'px'
        window.addEventListener('scroll', ()=>{
            myYears.animate(setUp.sizes);
            changeOpacityOfYearsOnScroll();
        });
    } 
)

/*
SWITCH BETWEEEN SCENES 
*/

let currentDetailContentIdx = 0;
let detailContent = new Detailcontent(currentDetailContentIdx);

window.addEventListener('click', () =>
{
    if(currentIntersect)
    {
       switchToDetail(currentIntersect)
    }
})

HTML.goToStartSceneBtn.addEventListener('click', ()=>{
    switchToStart();
})

HTML.startTimeTravel.addEventListener('click', () =>{
    animateTimeTravel();
});


for (const decade of HTML.timelineAnchor) {
    decade.addEventListener('click', (e)=>{
        const thisDecade = parseInt(e.target.innerText.substr(0,4))
        const idx = myYears.decadenumbers.indexOf(thisDecade);
        window.scrollTo({
            top: params.bodyScrollMeasure * idx * 10,
            left: 0,
            behavior: 'smooth'
        })
        
    });
}


/**
 * TICK
 */
// const clock = new THREE.Clock()

const tick = () =>
{
    // const elapsedTime = clock.getElapsedTime()
    // Update controls
    controls.update()
    // console.log(controls.enabled);

    raycaster.setFromCamera(mouse, camera);

    if (scopes.MeshesOfYears[0]) {
        const intersectionObjects = scopes.MeshesOfYears;
        const intersects = raycaster.intersectObjects(intersectionObjects)
        if(intersects.length)
        {
            if(!currentIntersect)
            {
                gsap.to(intersects[0].object.material.color, { duration: .2, r:params.colors.redRGB[0], g:params.colors.redRGB[1], b:params.colors.redRGB[2]}); 
                // intersects[0].object.material.color.set(params.colors.red)
            }
    
            currentIntersect = intersects[0]
        }
        else
        {
            if(currentIntersect)
            {
                // currentIntersect.object.material.color.set(params.colors.textcolor)
                gsap.to(currentIntersect.object.material.color, { duration: .2, r:1, g:1, b:1});
            }
            
            currentIntersect = null
        }


    }

    // Render
    renderer.render(currentScene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

const changeOpacityOfYearsOnScroll = ()=>{
    for (let i = 0; i < scopes.MeshesOfYears.length; i++) {
        var meshPosition = scopes.MeshesOfYears[i].getWorldPosition(new THREE.Vector3());
                
        if (frustum.containsPoint(meshPosition)) {
            gsap.to(scopes.MeshesOfYears[i].material, {duration:1, ease: "circ.inOut",  opacity: 1})
        }else{
            scopes.MeshesOfYears[i].material.opacity = 0;
           
        }
        
    }
}

//Debug
const debug = new Debug();

if (debug.active) {

    //Startscene
    debug.debugFolderYears = debug.ui.addFolder('Jahreszahlen');
    debug.debugFolderYears.addColor(params.colors, 'textcolor').name('Textfarbe').onChange(()=>{
        for(let material of scopes.MaterialsOfYears){
            material.color.set(params.colors.textcolor);
        }
    });
    debug.debugFolderYears
        .add(params, 'cameraFar', 5, 200, 1)
        .onChange(()=>{
            camera.far = params.cameraFar;
            camera.updateProjectionMatrix();
        })
        .name('Sichtweite')
        
    debug.debugFolderYears.add(params, 'canvasAlphaValue').name('AbdunkelFaktor').onChange(() =>{
        canvas.style.backgroundColor = `rgba(0,0,0, ${params.canvasAlphaValue})`
    }).min(0).max(1).step(0.01)    

    //Detailscene
    debug.debugFolderDetailScene = debug.ui.addFolder('Detailseite');
    debug.debugFolderDetailScene
        .add(detailContent, 'mediaCollageRadius')
        .onChange((e)=>{
            for (let i = 0; i < detailContent.meshes.length; i++) {
                detailContent.meshes[i].position.x = (i % 2 == 0) ? Math.cos(i)*e : -Math.cos(i-1)*e ; 
                detailContent.meshes[i].position.z = (i % 2 == 0) ? Math.sin(i)*e  : Math.sin(i-1)*e ; 
                
            }
            
        })
        .min(0.1)
        .max(10)
        .step(0.1)
        .name('Kreisradius');
    
    debug.debugFolderDetailScene
        .add(params.detailContent, 'ImgWidth')
        .onChange(()=>{
            for (const mesh of detailContent.meshes) {
                mesh.scale.set(params.detailContent.ImgWidth, params.detailContent.ImgHeight, 1)
            }
        })
        .min(0.1)
        .max(10)
        .step(0.1)
        .name('Bilderbreite');
        
    debug.debugFolderDetailScene
        .add(params.detailContent, 'ImgHeight')
        .onChange(()=>{
            for (const mesh of detailContent.meshes) {
                mesh.scale.set(params.detailContent.ImgWidth, params.detailContent.ImgHeight, 1)
            }
        })
        .min(0.1)
        .max(10)
        .step(0.1)
        .name('Bildhöhe');

    debug.stageFloor =  debug.debugFolderDetailScene.addFolder('Bühne');    
    debug.stageFloor
        .add(params.detailContent.stageFloor, 'yPos')
        .onChange(()=>{
            detailContent.stageFloor.position.y = params.detailContent.stageFloor.yPos
        })
        .min(-2)
        .max(2)
        .step(0.01)
        .name('Y-Position')   

    debug.stageFloor
        .add(params.detailContent.stageFloor, 'radius')
        .onChange(()=>{
            detailContent.stageFloor.scale.setScalar(params.detailContent.stageFloor.radius)
        })
        .min(0)
        .max(20)
        .step(0.01)
        .name('Radius')

    debug.stageFloor
        .addColor(params.detailContent.stageFloor, 'color')
        .onChange(()=>{
            detailContent.stageFloor.material.color.set(params.detailContent.stageFloor.color)
        })
        .name('Farbe')

    debug.stageFloor
        .add(params.detailContent.stageFloor, 'opacity')
        .onChange(()=>{
            detailContent.stageFloor.material.opacity = params.detailContent.stageFloor.opacity
        })
        .min(0)
        .max(1)
        .step(0.01)
        .name('Sichtbarkeit')


    debug.light =  debug.debugFolderDetailScene.addFolder('Licht');
    // debug.light
    //     .add(params.detailContent.stageLight, 'zpos')
    //     .onChange(()=>{
    //         detailContent.stageLight.position.z = params.detailContent.stageLight.zpos
    //     })
    //     .min(-2)
    //     .max(2)
    //     .step(0.01)
    //     .name('z-Position')

    // debug.light
    //     .add(params.detailContent.stageLight, 'ypos')
    //     .onChange(()=>{
    //         detailContent.stageLight.position.y = params.detailContent.stageLight.ypos
    //     })
    //     .min(0)
    //     .max(10)
    //     .step(0.01)
    //     .name('y-Position')

    // debug.light
    //     .add(params.detailContent.stageLight, 'xpos')
    //     .onChange(()=>{
    //         detailContent.stageLight.position.x = params.detailContent.stageLight.xpos
    //     })
    //     .min(-2)
    //     .max(2)
    //     .step(0.01)
    //     .name('x-Position')

    // debug.light
    //     .addColor(params.detailContent.stageLight, 'color')
    //     .onChange(()=>{
    //         detailContent.stageLight.color.set(params.detailContent.stageLight.color)
    //     })
    //     .name('Farbe')

    // debug.light
    //     .add(params.detailContent.stageLight, 'intensity')
    //     .onChange(()=>{
    //         detailContent.stageLight.intensity = params.detailContent.stageLight.intensity
    //     })
    //     .min(0)
    //     .max(1)
    //     .step(0.01)
    //     .name('Intensity')

    // debug.light
    //     .add(params.detailContent.stageLight, 'distance')
    //     .onChange(()=>{
    //         detailContent.stageLight.distance = params.detailContent.stageLight.distance
    //     })
    //     .min(0)
    //     .max(20)
    //     .step(0.01)
    //     .name('Distance')

    // debug.light
    //     .add(params.detailContent.stageLight, 'angle')
    //     .onChange(()=>{
    //         detailContent.stageLight.angle = params.detailContent.stageLight.angle
    //     })
    //     .min(0)
    //     .max(Math.PI*0.5)
    //     .step(0.01)
    //     .name('Angle')

    // debug.light
    //     .add(params.detailContent.stageLight, 'penumbra')
    //     .onChange(()=>{
    //         detailContent.stageLight.penumbra = params.detailContent.stageLight.penumbra
    //     })
    //     .min(0)
    //     .max(1)
    //     .step(0.01)
    //     .name('Penumbra')

    // debug.light
    //     .add(params.detailContent.stageLight, 'decay')
    //     .onChange(()=>{
    //         detailContent.stageLight.decay = params.detailContent.stageLight.decay
    //     })
    //     .min(0)
    //     .max(2)
    //     .step(0.01)
    //     .name('Decay')

    var obj = { 
        toggleFakeSpotlight:function(){ 
            if (HTML.fakeSpotlight.classList.contains('none')) {
                HTML.fakeSpotlight.classList.remove('none')
            }else{
                HTML.fakeSpotlight.classList.add('none')
            }    
        },
        toggleSpotlightOverlay: ()=>{
            if (HTML.spotlightOverlay.classList.contains('none')) {
                HTML.spotlightOverlay.classList.remove('none')
            }else{
                HTML.spotlightOverlay.classList.add('none')
            }
        }   
    };

    debug.light.add(obj,'toggleFakeSpotlight').name('ToggleFakeSpotlight');
    debug.light.add(obj,'toggleSpotlightOverlay').name('ToggleFakeOverlay');

    
}




/**
 * Functions
 */

const switchToDetail = (Intersect) =>{
    currentDetailContentIdx = Intersect.object.detailContentIdx;
    detailContent = new Detailcontent(currentDetailContentIdx);
    detailContent.writeHTML();
    detailContent.createMeshes();
    scenes.stageScene.add(detailContent.mediaCollage);
    scenes.stageScene.add(detailContent.stageFloor);
    // scenes.stageScene.add(detailContent.stageLight);

    setTimeout(() => {
        controls.enabled = true;
        currentScene = scenes.stageScene;
        detailContent.rotateMeshes(0.5);
        detailContent.styleHTML();
    }, 300);
    
    // gsap.to(myYears.sceneGroup.rotation, {duration: 0.5, delay:0, x:1})
    setOpacityofChildren(myYears.sceneGroup, 0, 0.3)

}

const switchToStart = ()=> {
    setTimeout(() => {
        scenes.stageScene.remove(detailContent.mediaCollage);
        disposeChildren(detailContent.mediaCollage)
        currentScene = scenes.startscene;
        controls.enabled = false;
        camera.position.set(0,0,1);
        setOpacityofChildren(myYears.sceneGroup, 1, 1)
    }, 400);

    // gsap.to(myYears.sceneGroup.rotation, {duration: 0.5, delay:0.5, x:0})
    

    styleHtmlOfStartScene();
}


const styleHtmlOfStartScene = () =>{
    HTML.goToStartSceneBtn.classList.remove('visible');
    HTML.goToHomepage.classList.add('visible');
    HTML.detailText.classList.remove('visible');
    HTML.fakeSpotlight.classList.remove('active');
    HTML.spotlightOverlay.classList.remove('visible');
    HTML.controlsInfo.classList.remove('visible');
    setTimeout(() => {
        HTML.main.style.backgroundImage = 'url(' + myYears.decadeBGImages[myYears.bgImgCounter] + ')';
        HTML.timelinewrap.classList.add('visible');
    }, 300);

}

const setOpacityofChildren = (group, opacity, duration) =>{
        group.traverse(child => {
        if(child instanceof THREE.Mesh){
            gsap.to(child.material, {duration: duration, ease: 'none', opacity: opacity})
        }
    })
}

const disposeChildren = (group) =>{
    group.traverse(child => {
        if(child instanceof THREE.Mesh){
            child.material.dispose();
            child.geometry.dispose();
        }
    });
}

const animateTimeTravel = () => {
    const animationDuration = 1.5;
    gsap.to(myYears.sceneGroup.position, { duration:animationDuration, z:0, ease: "none"});
    for (let i = 0; i <= myYears.decadeBGImages.length; i++) {    
        switchMainBG(myYears.decadeBGImages.length - i, animationDuration / myYears.decadeBGImages.length * 1000 * i)   
    }
}

const switchMainBG = (counter,timeout) =>{
    setTimeout(() => {
        HTML.main.style.backgroundImage = 'url(' + myYears.decadeBGImages[counter] + ')';
    }, timeout);
}

const pageAccessedByReload = (
    (window.performance.navigation && window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
  );

  
if (pageAccessedByReload) {
    window.scrollTo(0, 0);
}