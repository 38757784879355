import params from "./parameters";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { 
    WebGLRenderer,
    Scene,
    PerspectiveCamera,
    Frustum,
    Matrix4,
    Vector2 
} from "three";

export default class SetUp{
    constructor(canvas){

        this.canvas = canvas;

        this.scenes = {
            startscene: new Scene(),
            stageScene: new Scene(),
            detailScene: new Scene()
        };

        this.sizes = {
            width: window.innerWidth,
            height: window.innerHeight,
            pixelRatio: Math.min(window.devicePixelRatio, 2)
        }

        this.renderer = new WebGLRenderer({
            canvas: canvas, 
            alpha: true,
        });
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        this.camera = new PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, params.cameraFar);
        this.camera.position.set(0,0,1);
        this.camera.updateMatrix();
        this.camera.updateMatrixWorld();

        this.frustum = new Frustum();
        this.frustum.setFromProjectionMatrix(new Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse));

        this.controls = new OrbitControls(this.camera, this.canvas);
        this.controls.enableDamping = true;
        this.controls.maxDistance = 1;
        this.controls.maxPolarAngle = Math.PI*0.5;
        this.controls.minPolarAngle = Math.PI*0.5;
        this.controls.enablePan = false;
        
        this.mouse = new Vector2();
        this.mouse.x = 999999;
        this.mouse.y = 999999;

    }

    updateOnResize(){
        this.sizes.width = window.innerWidth;
        this.sizes.height = window.innerHeight;
        this.sizes.pixelRatio = Math.min(window.devicePixelRatio, 2);
         // Update camera
        this.camera.aspect = this.sizes.width / this.sizes.height;
        this.camera.updateProjectionMatrix();
         // Update renderer
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }

    updateMouseOnMove(event){
        this.mouse.x = event.clientX / this.sizes.width * 2 - 1;
        this.mouse.y = - (event.clientY / this.sizes.height) * 2 + 1
    }
}