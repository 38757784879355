import HTML from "./HTML";
import params from "./parameters";

let zAxis = params.years.meshOptions.zAxis;
const deltaZ = params.years.meshOptions.zAxis.zDistanceBetweenDecades;

export function animateOnScroll(years, sizes){
    years.sceneGroup.position.z = params.years.scrollSpeed*window.scrollY/sizes.height;
    jumpToDecade(years);

}

export function jumpToDecade(years){
    if (years.bgImgCounter <= years.decadesLength ) {   
        if(years.sceneGroup.position.z >= zAxis.zDistanceBetweenDecades){
            zAxis.zDistanceBetweenDecades += deltaZ;  
            years.bgImgCounter++;
            years.bgImgCounter = Math.min(years.bgImgCounter, years.decadesLength); 
            if (years.bgImgCounter < years.decadesLength) switchDecadeBackgroundImage(years, HTML.main, years.bgImgCounter);
            // HTML.timelineMarker.style.top = 'calc(' + document.querySelector('.t' + years.decadenumbers[years.bgImgCounter].toString()).getBoundingClientRect().top.toString() + 'px + 0.5em)';
            
        }
        else if(years.sceneGroup.position.z < zAxis.zDistanceBetweenDecades - deltaZ){
            zAxis.zDistanceBetweenDecades -= deltaZ;
            zAxis.zDistanceBetweenDecades = Math.max(zAxis.zDistanceBetweenDecades, 0);
            years.bgImgCounter--;
            switchDecadeBackgroundImage(years, HTML.main, years.bgImgCounter);
        }
    }
}

const switchDecadeBackgroundImage = (years, element, counter) => {
    element.classList.add('fadeOut');
    setTimeout(() => {
        element.style.backgroundImage = 'url(' + years.decadeBGImages[counter] + ')';
        element.classList.add('fadeIn');
    }, 1000);
    setTimeout(() => {
        element.classList.remove('fadeOut');
        element.classList.remove('fadeIn');
    }, 2000);
    HTML.timelineMarker.style.top = 'calc(' + document.querySelector('.t' + years.decadenumbers[counter].toString()).getBoundingClientRect().top.toString() + 'px + 0.5em)';  
}

