export default {
    bodyScrollMeasure: window.innerHeight * 0.59, // !! depends on parameters.years.meshOptions.zAxis.position
    cameraFar: 25,
    colors: {
        fogcolor: 0x808080,
        textcolor: 0xffffff,
        spotlightcolor: 0x707070,
        red: 0xD9422D,
        redRGB: [0.850980392156, 0.25882352941176473, 0.17647058823529413]
    },
    canvasAlphaValue: 0.2,
    years:{
        startYear: 1958,
        fontSizes:{
            forYears:{
                decade: 1.5,
                nondecade: 0.3,
            },
            forTitle:{
                decade: 0.4,
                nondecade: 0.1,
            }
        },
        meshOptions:{
            zAxis: {
                position: -3, // !! on update: also update parameters.bodyScrollMeasure
                zDistanceBetweenDecades : 28 // position * 10
            },
        },
        scrollSpeed: 5
    },
    detailContent:{
        ImgWidth:1,
        ImgHeight:1,
        zAxisAreaForTextVisibility: -0.95,
        stageFloor:{
            radius: 2,
            yPos: -0.5,
            color: '#ffffff',
            opacity: 0.14,
        },
        stageLight:{
            zpos: 0,
            ypos: 0,
            xpos: 0,
            target:{
                x:0,
                y:0,
                z:0
            },
            color: '#ffffff',
            intensity: 1,
            distance: 0,
            angle: 1,
            penumbra:0,
            decay:0,
            power:0
        }
    }
}