//for Testing
const urls = {
    echonet: '/assets/content/',
    vsCode: 'sh-assets/media/'
}

//fill decades BG Images
export default {
    landingpage: urls.vsCode + 'Eisrevue_BG.jpg',
    stage: urls.vsCode +'Eroeffnung_BG_02.jpg',
    sample1: urls.vsCode +'sample1.jpg',
    sample2: urls.vsCode +'sample2.jpg'
}