import $ from "jquery";
import Years from "./Years";

const years = new Years();

const timeline = $('#timeline');
let timelineLi = document.createElement("li");
let timelineElement = document.createElement("p");
let timelineText = document.createTextNode("Water");

// var decades = ['1960', '1970', '1980', '1990', '2000', '2010', '2020'];
const decades = years.decadenumbers.reverse();

export function writeTimelineHTML(){
    for(let i=0; i < decades.length; i++){
        timelineLi = document.createElement("li");
        timelineLi.classList.add('timelineelement');
        timelineLi.classList.add('t' + decades[i].toString());
        timelineElement = document.createElement("p");
        timelineLi.appendChild(timelineElement);
        timelineText = document.createTextNode(decades[i] + 'er');
        timelineElement.appendChild(timelineText);
        timeline.append(timelineLi);
        // $('#vorspannbox').append('<div class="vorspanntext" id="vorspann' + i.toString() +
        // '"><h1>'+i.toString() + '</h1><h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, nesciunt enim, eligendi cumque dolore debitis illo omnis, molestiae eaque unde rem. Deleniti corporis laudantium, cum blanditiis magnam hic consequatur ea.</h3></div>');
    }
}

